//封装axios请求
import axios from 'axios'

const STATIC_URL = () => {
    if(process.env.NODE_ENV == "development") {
        return "https://share.thucps.com"
    }else if(process.env.DOMAIN == "test") {
        return "https://share.thucps.com"
    }else {
        // return "https://share.thucps.com"
        return "https://share.thucps.com"
    }
}

export const staticURL = STATIC_URL()

const instance = axios.create({
    timeout: 10000,
    baseURL: process.env.NODE_ENV == 'development' ? "https://share.thucps.com" : "",
    headers: {
        "Content-Type": "application/json; charset=UTF-8;",
        userId: KSX.getCookie("userId"),
        token: KSX.getCookie("token")
    },
    withCredentials: true,
})

instance.interceptors.response.use(
    res => res.data,
    err => Promise.reject(err)
)

export const get = (url, data, config = {}) => {
    const instance = axios.create({
        timeout: 10000,
        baseURL: process.env.NODE_ENV == 'development' ? "https://share.thucps.com" : "",
        headers: {
            "Content-Type": "application/json; charset=UTF-8;",
            userId: KSX.getCookie("userId"),
            token: KSX.getCookie("token")
        },
        withCredentials: true,
    })
    
    instance.interceptors.response.use(
        res => res.data,
        err => Promise.reject(err)
    )

    return instance.get(url, data, config)
}
export const post = (url, data, config) => {
    return instance.post(url, data, {
        headers: {
            "Content-Type": "application/json; charset=UTF-8;",
            userId: KSX.getCookie("userId"),
            token: KSX.getCookie("token")
        },
        ...config
    })
}
export const dlt = (url, data, config = {}) => {
    const instance = axios.create({
        timeout: 10000,
        baseURL: process.env.NODE_ENV == 'development' ? "https://share.thucps.com" : "",
        headers: {
            "Content-Type": "application/json; charset=UTF-8;",
            userId: KSX.getCookie("userId"),
            token: KSX.getCookie("token")
        },
        withCredentials: true,
    })
    
    instance.interceptors.response.use(
        res => res.data,
        err => Promise.reject(err)
    )

    return instance.delete(url, data, config)
}