import { post,get } from "@/utils/http"

// 拦截器，登录检查，不同设备跳转
export default function (router) {
  router.beforeEach((to, from, next) => {
    if (to.meta.title) { //如果设置标题，拦截后设置标题
      document.title = to.meta.title
    }

    let nextUrl = to.query.nextUrl
    // 登录地址
    let jumpUrl = '/login' + '?nextUrl=' + encodeURIComponent(window.location.href)

    // 如果不需要登录校验 // ignoreLoginCheck 写在当前router的meta中（信息元）
    if (to.matched.some(record => record.meta.ignoreLoginCheck)) {
      next()
    }else {
      post("/login/login_check").then((data) => {
        if (data.success) {
          if(to.matched.some(record => record.meta.title==="登录")) {
            // 有nextUrl则跳转nextUrl，没有则跳转到默认列表页
            if(nextUrl) {
              window.open(nextUrl, '_self'); 
            }else {
              router.push("/index")
            }
          }else {
            router.app.$store.commit('setUserInfo', data.bizContent)

            if(to.matched.some(record => record.meta.checkRights==="checkModel")) {
              router.app.$store.dispatch('checkModel', to.params.id).then(res => {
                if(res.success) {
                  if(res.code == 10000) {
                    next()
                  }else {
                    router.push('/denied?code='+res.code)
                  }
                }else {
                  router.push('/denied?code='+res.code)
                }
              }).catch(err => {
                console.log(err)
              })
            }else {
              if(to.matched.some(record => record.meta.role==="admin")) { // 管理后台
                if(data.bizContent.role == 4) { // 成员
                  router.push('/index')
                }else {
                  next()
                }
              }else {
                next()
              }
            }
          }
          if(to.meta.title == '项目详情'){
            get(`/item/itemAuthority/${to.params.id}`).then((res)=>{
              if(!res.success){
                if(res.code == "35003" || res.code == "33008" || res.code == "31002"){
                  router.push('/denied?code='+res.code)
                }
              }
            })
          }
        } else {
          router.app.$store.commit('clearUserInfo')

          if(to.matched.some(record => record.meta.canGuest)) { // 未登录，需校验元模型是否为全平台共享
            if(to.matched.some(record => record.meta.checkRights==="checkModel")) {
              router.app.$store.dispatch('checkModel', to.params.id).then(res => {
                if(res.success) {
                  if(res.code == 10000) {
                    next()
                  }else {
                    router.push('/denied?code='+res.code)
                  }
                }else {
                  router.push('/denied?code='+res.code)
                }
              }).catch(err => {
                console.log(err)
              })
            }else {
              next()
            }
          }else {
            router.push(jumpUrl)
          }
        }
      }).catch(err => {
        console.log(err)
      })
      
    }
  });
}
