import Vue from 'vue'
import App from '@/App.vue'
import router from './router'
import store from '@/store'
import ElementUI from 'element-ui';
import axios from 'axios'
import Bus from '@/utils/bus'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/header.scss'
import '@/assets/base.scss'
import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards) //必须这样子引入才可以

Vue.use(ElementUI,axios,Bus);
axios.defaults.baseURL='https://share.thucps.com'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-from-urlencoded;charset=utf-8'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
