import Vue from 'vue'
import VueRouter from 'vue-router'
import interceptor from '@/router/interceptor'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/index',
    component: () => import('../components/Index.vue'),
    meta: {
      tab: 1,
      title: "元模型",
      canGuest: true
    },
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../components/Index.vue'),
    meta: {
      tab: 1,
      title: "元模型",
      canGuest: true
    },
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../components/CreateMetaModel.vue'),
    meta: {
      tab: 5,
      title: "上传元模型"
    },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../components/ModelDetail.vue'),
    meta: {
      tab: 1,
      title: "元模型详情",
      checkRights: "checkModel",
      canGuest: true
    },
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import('../components/CreateMetaModel.vue'),
    meta: {
      tab: 5,
      title: "编辑元模型",
      checkRights: "checkModel"
    },
  },
  {
    path: '/dbmeta',
    name: 'dbmeta',
    component: () => import('../components/DbMeta.vue'),
    meta: {
      tab: 2,
      title: "元元模型",
      canGuest: true
    },
  },
  {
    path: '/editdb',
    name: 'editdb',
    component: () => import('../components/CreateDbMeta.vue'),
    meta: {
      tab: 2,
      title: "编辑元元模型"
    },
  },
  {
    path: '/function',
    name: 'function',
    component: () => import('../components/Function.vue'),
    meta: {
      tab: 3,
      title: "方法案例",
      canGuest: true
    },
  },
  {
    path: '/study',
    name: 'study',
    component: () => import('../components/Study.vue'),
    meta: {
      tab: 4,
      title: "学习资源",
      canGuest: true
    },
  },
  {
    path: '/project',
    name: 'myProject',
    component: () => import('../components/MyProject.vue'),
    meta: {
      tab: 5,
      title: "我的项目",
    },
  },
  {
    path: '/downloadAudit/:id',
    name: 'downloadAudit',
    component: () => import('../components/DownloadAudit.vue'),
    meta: {
      tab: 5,
      title: "下载审核",
    },
  },
  {
    path: '/projectDetail/:id',
    name: 'projectDetail',
    component: () => import('../components/ProjectDetail'),
    meta: {
      tab: 5,
      title: "项目详情",
    },
  },
  {
    path: '/join/:projectId',
    name: 'joinProject',
    component: () => import('../components/JoinProject'),
    meta: {
      tab: 5,
      title: "我的项目",
    },
  },
  {
    path: '/admin',
    name: 'adminIndex',
    component: () => import('../components/AdminIndex.vue'),
    meta: {
      tab: 6,
      title: "后台管理",
      role: "admin"
    },
  },
  {
    path: '/join',
    name: 'joinProject',
    component: () => import('../components/JoinProject'),
    meta: {
      tab: 5,
      title: "我的项目",
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login.vue'),
    meta: {
      title: "登录",
      canGuest: true
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/Register.vue'),
    meta: {
      title: "注册",
      ignoreLoginCheck: true
    }
  },
  {
    path: '/denied',
    name: 'denied',
    component: () => import('../components/Denied.vue'),
    meta: {
      title: "报错页",
      ignoreLoginCheck: true
    }
  },
  // {
  //   path: '*',
  //   component: () => import('../Entrance.vue'),
  //   meta: {
  //     title: "服务创新工具"
  //   }
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// const originalPush = VueRouter.prototype.push
//   VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

interceptor(router)


export default router 
