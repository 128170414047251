<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath"/>
    </keep-alive>
  </div>
</template>
<script>

export default {
  mounted() {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
    window.addEventListener('hashchange', () => {
      let currentPath = window.location.hash.slice(1)
      
      if (this.$route.path !== currentPath) {
        this.$router.push(currentPath)
      }
    }, false)
  }
}
</script>
<style>
body{
  margin:0;
}
</style>
