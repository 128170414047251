import Vue from 'vue'
import Vuex from 'vuex'
import { post } from "@/utils/http"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /**
     * role--项目角色
     * 1：超级管理员
     * 2：系统管理员
     * 3：助理
     * 4：成员
     * **/

    // 用户信息
    userInfo: {
      userId: "",
      username: "",
      nickname: "",
      phone: "",
      picture: "",
      role: 4,
      color: "",
      projectPoint:false,//我的项目的红点
      registerPoint:false,//注册审核的红点
      sharePoint:false,//共享管理的红点
    },
    // 项目角色 1:创建人; 2:负责人; 3:成员; 4:超管或系统管理员; 5:项目外成员
    modelRole: {
      role: "",
      download: false
    },
    dialogs: {
      showSinglePhotoEnlarge: false
    }
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    modelRole(state) {
      return state.modelRole
    },
    dialogs(state) {
      return state.dialogs
    }
  },
  mutations: {
    setUserInfo(state, params) {
      state.userInfo.userId = params.userId
      state.userInfo.username = params.username
      state.userInfo.nickname = params.nickname
      state.userInfo.phone = params.phone
      state.userInfo.picture = params.picture
      state.userInfo.role = params.role
      state.userInfo.color = params.color
      state.userInfo.projectPoint = params.projectPoint
      state.userInfo.registerPoint = params.registerPoint
      state.userInfo.sharePoint = params.sharePoint
    },
    clearUserInfo(state, params) {
      state.userInfo.userId = ""
      state.userInfo.username = ""
      state.userInfo.nickname = ""
      state.userInfo.phone = ""
      state.userInfo.picture = ""
      state.userInfo.role = ""
      state.userInfo.color = ""
      state.userInfo.projectPoint = false
      state.userInfo.registerPoint = false
      state.userInfo.sharePoint = false
    }
  },
  actions: {
    checkModel(context, modelId) {
      return new Promise((resolve, reject) => {
        post(`/model/role_check/${modelId}`).then(res => {
          if(res.success) {
            context.state.modelRole = res.bizContent || {
              role: 5,
              download: false
            }
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
})
